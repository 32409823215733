.gallery-container{
    display: flex;
    flex-wrap: wrap;
    .gallery-item{
        width: 100px;
        margin-right: 10px;
        margin-bottom: 10px;
        @media (min-width: 1200px){
            width: 200px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}