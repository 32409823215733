.home-card-requests{
    padding-top: 50px;
    padding-bottom: 50px;
    .container{
        display: flex;
        flex-wrap: wrap;
        @media (min-width: 1200px){
            width: 1000px;
            justify-content: space-between;
        }
        .home-card-requests__item{
            width: 100%;
            height: 280px;
            position: relative;
            margin-bottom: 20px;
            @media (min-width: 1200px){
                width: 450px;
                margin-bottom: 40px;
            }
        }

        .home-card-requests__item__bg-image{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            img{
                width: 100%;
                height: 100%;
                object-position: cover;
            }
        }

        .home-card-requests__item__text-content{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 50px 20px;
            color: #fff;
        }

        .home-card-requests__item__title{
            font-size: 24px;
            width: 70%;
            line-height: 1.2;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        .home-card-requests__item__subtitle{
            margin-bottom: 30px;
            width: 70%;
        }

        .home-card-requests__item__button{
            display: inline-block;
            padding: 7px 20px;
            border-radius: 20px;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
            transition: 0.4s;
            &:hover{
                opacity: 0.8;
            }
            i{
                margin-right: 10px;
            }
        }

        .home-card-requests__item__button--bg-white{
            background-color: #fff;
            color: #014485;
        }

        .home-card-requests__item__button--bg-blue{
            background-color: #014485;
            color: #fff;
        }
    }
}
