.clearfix {
    *zoom: 1;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}
