.container {
    @include container($width: 130rem, $padding: 0 2rem);

    @include media(get-bp(desktop)) {
        padding: 0 3rem;
    }
}

.container--content {
    @include container($width: 100rem, $padding: 0);
}

.left-container, .right-container {
    padding: 0 2rem;
    
    @include media(get-bp(desktop)) {
        padding: 0 3rem;
    }
}