.gallery-container{
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    margin-left: -2%;
}

.gallery-container__gallery-item{
    width: 31.33%;
    margin-left: 2%;
    margin-bottom: 1%;
    height: 100px;
    overflow: hidden;
    @media (min-width: 1200px){
        height: 150px;
        width: 23%;
        margin-bottom: 1.5%;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.modal-gallery{
    .modal__box{
        @media (min-width: 1200px){
            width: 700px;
        }
    }

    .gallery-modal-slider{
        width: 100%;
        height: 450px;
    }
}
