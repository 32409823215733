.preloader-js{
    position: absolute;
    z-index: 300;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader-js__spinner{
    width: 40px;
    height: 40px;
    border: 5px solid get-color(gray);
    border-top-color: get-color(primary);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    
    @keyframes spin{
        to {transform: rotate(360deg);}
    }
}
