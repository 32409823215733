.page-zero {
    .zero {
        height: 670px;

        @include media (get-bp(desktop)) {
            height: 825px;
        }

        &::before {
            content: "";
            width: 100%;
            height: 670px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(180deg, rgba(0, 0, 0, 0.7) 8.97%, rgba(0, 0, 0, 0.49) 23.76%, rgba(0, 0, 0, 0) 81.83%);
            position: absolute;
            top: 0;
            left: 0;

            @include media (get-bp(desktop)) {
                height: 825px;
            }
        }

        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }

        .container {
            height: 0;
            z-index: 1;
        }
    }

    .zero__block {
        height: 670px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 48px;

        @include media (get-bp(desktop)) {
            height: 825px;
        }

        h4 {
            color: get-color(light);
            font-weight: 400;
            font-size: 48px;
            line-height: 48px;
            margin: 0;
            text-align: center;

            @include media (get-bp(desktop)) {
                font-size: 64px;
                line-height: 64px;
            }

            b {
                font-weight: 600;
            }
        }

        .block__button {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;

            @include media (get-bp(desktop)) {
                flex-direction: row;
            }

            a {
                display: flex;
                align-items: center;
                gap: 10px;
                background-color: rgba(0, 174, 239, 0.7);
                padding: 14px 24px;
                border-radius: 100px;
                transition: .2s;
                height: 45px;

                &:first-of-type {
                    background-color: rgba(16, 166, 80, 0.8);
                }

                &:hover {
                    opacity: .9;
                }
                
                p {
                    font-family: $font-family-primary;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17px;
                    color: get-color(light);
                    margin: 0;
                }

                img {
                    width: 49px;
                    height: 25px;
                    display: block;
                    object-fit: cover;
                }
            }
        }
    }
}