.footer-container{
    background-color: get-color(primary);
    padding-top: 52px;
    padding-bottom: 52px;

    .container {
        @include media (get-bp(desktop)) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
}

.footer-container__row {
    width: 100%;

    &:first-of-type {
        @include media (get-bp(desktop)) {
            display: grid;
            grid-template-columns: 1fr;
            align-items: flex-start;
        }
    }
}

.footer-container__first-column {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;
    
    @include media (get-bp(desktop)) {
        flex-direction: row;
        align-items: center;
    }

    a {
        font-family: $font-family-secondary;
        color: get-color(light);
        font-size: 16px;
        font-weight: 400;
        line-height: 25.6px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
}

.footer-logo {
    img {
        display: block;
        width: 100%;
        max-width: 155px;
        object-fit: contain;
    }
}

.footer-container__second-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 0;
    gap: 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 24px;
    margin-bottom: 40px;

    @include media (get-bp(desktop)) {
        flex-direction: row;
    }

    .footer__list {
        display: grid;
        grid-template-columns: 40px 1fr;
        align-items: center;
        gap: 12px;

        .list__icon {
            display: flex;
            align-items: center;
            justify-content: center;        
            height: 40px;
            width: 40px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 100px;
        }

        .list__text {
            h4 {
                color: get-color(light);
                font-family: $font-family-primary;
                font-size: 12px;
                font-weight: 600;
                line-height: 19px;
                margin: 0;
            }

            p{
                color: get-color(light);
                font-family: $font-family-primary;
                font-size: 12px;
                font-weight: 400;
                line-height: 19px;
                margin: 0;
            }
        }
    }

    .social-listing {
        justify-content: flex-start;

        @include media (get-bp(desktop)) {
            justify-content: center;
        }
    }
}

.footer-container__copyright {
    @include media (get-bp(tablet-portrait)) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    @include media (get-bp(desktop)) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    p {
        font-size: 14px;
        color: get-color(light);
        margin-bottom: 15px;

        @include media (get-bp(desktop)) {
            margin-bottom: 0;
        }

        a {
            text-decoration: underline;
        }
    }

    img {
        display: block;
        width: 100%;
        max-width: 80px;
    }
}