.social-listing{
    display: flex;
    justify-content: center;
    gap: 13px;

    li {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 100px;
    }

    .social-listing__item{
        display: block;
        height: 24px;
        width: 24px;
        font-size: 20px;
        color: get-color(primary);
        transition: 400ms;

        &:hover{
            transform: rotate(10deg);
        }
    }
}
