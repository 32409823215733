// **
// * Sticky Footer
// **

html,
body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.page-wrapper {
    &.content-wrapper {
        flex: 1 0 auto;
    }

    &.footer-wrapper {
        flex-shrink: 0;
    }
}
