// Importe webfonts aqui.
//
// @example
//     @include import-font-face('Nome da fonte', '/caminho/da/fonte', $weight?, $style?);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto');
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-italic', normal, italic);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-bold', bold);
//     @include import-font-face('Roboto', '../fonts/roboto/roboto-bold-italic', bold, italic);

    @include import-font-face('jeko', '../fonts/jeko/jeko_black-webfont', 900);
    @include import-font-face('jeko', '../fonts/jeko/jeko_black_italic-webfont', 900, italic);

    @include import-font-face('jeko', '../fonts/jeko/jeko_extra_bold-webfont', 800);
    @include import-font-face('jeko', '../fonts/jeko/jeko_extra_bold_italic-webfont', 800, italic);

    @include import-font-face('jeko', '../fonts/jeko/jeko_bold-webfont', 700);
    @include import-font-face('jeko', '../fonts/jeko/jeko_bold_italic-webfont', 700, italic);

    @include import-font-face('jeko', '../fonts/jeko/jeko_semi_bold-webfont', 600);
    @include import-font-face('jeko', '../fonts/jeko/jeko_semi_bold_italic-webfont', 600, italic);
    
    @include import-font-face('jeko', '../fonts/jeko/jeko_medium-webfont', 500);
    @include import-font-face('jeko', '../fonts/jeko/jeko_medium_italic-webfont', 500, italic);

    @include import-font-face('jeko', '../fonts/jeko/jeko_regular-webfont', 400);
    @include import-font-face('jeko', '../fonts/jeko/jeko_regular_italic-webfont', 400, italic);
    
    @include import-font-face('jeko', '../fonts/jeko/jeko_light-webfont', 300);
    @include import-font-face('jeko', '../fonts/jeko/jeko_light_italic-webfont', 300, italic);
    
    @include import-font-face('jeko', '../fonts/jeko/jeko_extra_light-webfont', 200);
    @include import-font-face('jeko', '../fonts/jeko/jeko_extra_light_italic-webfont', 200, italic);

    @include import-font-face('jeko', '../fonts/jeko/jeko_thin-webfont', 100);
    @include import-font-face('jeko', '../fonts/jeko/jeko_thin_italic-webfont', 100, italic);