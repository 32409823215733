$spacing-smaller: 0.5rem;
$spacing-small: 1rem;
$spacing-default: 2rem;
$spacing-big: 4rem;
$spacing-bigger: 5rem;

$spacing: (
    smaller: 0.5rem,
    small: 1rem,
    default: 2rem,
    big: 4rem,
    bigger: 5rem
);
