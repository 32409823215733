.page-default {
    padding: 12rem 0 4rem;

    h2 {
        font-size: 32px;

        @include media(get-bp(tablet-portrait)) {
            font-size: 40px;
        }
    }

    h3 {
        font-size: 28px;
    }

    h4 {
        font-size: 20px;
    }

    img {
        margin: 3rem 0;
        width: 100%;
        object-fit: cover;
        display: block;
    }

    p {
        letter-spacing: 0.5px;
        line-height: 1.7;
    }

    ul, ol {
        padding-left: 1.5rem;
    }
}