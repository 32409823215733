.notice__item {
    width: 100%;
    box-shadow: 
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 6px rgba(0, 0, 0, 0.04), 
    0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background-color: get-color(light);
    margin-bottom: 20px;

    @include media(get-bp(tablet-portrait)) {
        height: 440px;
    }

    .item__content {
        padding: 20px;
    }

    .content__photo {
        width: 100%;
        height: 182px;
        margin-bottom: 24px;
        display: block;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }

    .content__info {
        @include media(get-bp(tablet-portrait)) {
            height: 187px;
            overflow: auto;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: get-color(primary);
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: get-color(grey-dark);
        }

        small {
            color: get-color(form-border);
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.4px;
            font-family: $font-family-secondary;
            margin-bottom: 14px;
        }

        a {
            display: block;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.25px;
            color: get-color(primary);
            line-height: 24px;
            margin-bottom: 24px;
        }

        p {
            color: rgba(0, 0, 0, 0.6);
            letter-spacing: 0.25px;
            font-size: 14px;
            font-family: $font-family-secondary;
        }
    }
}
