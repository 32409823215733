//posicionamento do menu fixo no topo
.main-header-bundle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
}

.main-header {
    h1 {
        margin: 0;
    }
    &.change-color{
        background-color: rgba(#000, .5);
        box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.1);
    }
}

.menu-buttons{
    @include media(get-bp(desktop)){
        display: flex;
        align-self: center;
    }
    a{
        padding: 5px 15px;
        display: block;
        background-color: get-color(primary);
        margin-bottom: 20px;
        text-align: center;
        border-radius: 5px;
        color: get-color(secondary);
        font-weight: 600;
        transition: 400ms;

        @include media(get-bp(desktop)){
            margin-right: 20px;
            margin-bottom: 0;
        }
        &:hover{
            background-color: get-color(secondary);
            color: get-color(light);
        }
    }
}

.header-content {
    display: flex;
    align-items: center;
    
    .menu-content {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 30%;
        background-color: get-color(bg-menu);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.8px);
        -webkit-backdrop-filter: blur(8px);
        transform: translateX(335%);
        transition: 400ms;

        &.is-active {
            transform: translateX(234%);
        }
    }

    .close-menu {
        height: 32px;
        margin-left: auto;
        margin-bottom: 48px;

        &:hover {
            cursor: pointer;
        }
    }
}

//menu desktop
.desktop-menu {
    display: none;
    @media (min-width: 1200px) {
        display: block;
    }
    .container {
        display: flex;
        justify-content: space-between;
    }

    .main-header__logo-container {
        width: 110px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-right: 40px;
        
        a{
            display: flex;
        }
    }

    .main-header__logo {
        width: 100%;
        height: 100%;
        display: inline-block;
        object-fit: contain;
    }

    .header-content{
        display: flex;
        align-items: center;
    }
    .social-listing{
        .social-listing__item{
            color: get-color(light);
        }
    }

    .group-language {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 24px;
        gap: 8px;

        .language__list {
            display: flex;
            align-items: center;
            gap: 6px;

            a {
                &.is-active {
                    h4 {
                        font-weight: 600;
                    }
                }

                &:last-of-type {
                    h4 {
                        border-right: none;
                        margin-right: 0;
                    }
                }
            }

            h4 {
                color: get-color(light);
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0.002em;
                margin: 0;
                border-right: 1px solid get-color(light);
                padding-right: 6px;
            }
            
        }
    }

    .menu-container{
        form {
            border-bottom: 1px solid #fff;
            margin-bottom: 32px;
            padding-bottom: 32px;

            .form-group {
                display: flex;
                align-items: center;
            }

            .form-control {
                font-family: $font-family-primary;
                background-color: transparent;
                font-size: 16px;
                font-weight: 400;
                line-height: 25px;
                color: get-color(light);
                margin: 0;
                border: none;
                padding-left: 0;

                &::placeholder {
                    color: rgba(get-color(light), .8);
                }

                &:focus {
                    border: none;
                    box-shadow: none;
                }
            }

            .input-icon__icon {
                height: 24px;
            }
        }

        .social-listing {
            display: flex;
            justify-content: flex-start;
        }

        @include media(get-bp(desktop)){
            display: flex;
            height: 100%;
            flex-direction: column;
            padding: 24px;
        }

        .menu-container__listing{
            display: flex;
            flex-direction: column;
            flex: 0;        

            >li{
                position: relative;

                >.submenu{
                    display: grid;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    bottom: 0;
                    transform: translateY(100%);
                    position: absolute;
                    background-color: get-color(primary);
                    color: get-color(secondary);
                    opacity: 0;
                    visibility: hidden;
                    max-height: 200px;
                    width: 200px;
                    padding: 10px;
                    row-gap: 10px;
                    overflow: auto;
                    transition: 400ms;

                    li:hover{
                        text-decoration: underline;
                    }
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1; 
                    }
                    &::-webkit-scrollbar-thumb {
                        background: get-color(grey-light); 
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background-color: get-color(grey-dark);
                    }
                }
                &:hover{
                    >.submenu{
                        visibility: visible;
                        opacity: 100%;
                    }
                }
            }
            >li{
                transition: 400ms;

                @include media(get-bp(desktop)){
                    display: flex;
                    border-bottom: 1px solid get-color(light);
                    margin-bottom: 32px;
                    padding-bottom: 32px;
                }

                &:hover{
                    color: get-color(primary);
                }
            }

            a{
                font-family: $font-family-primary;
                color: get-color(light);
                font-size: 16px;
                font-weight: 400;
                line-height: 25px;
                margin: 0;
                transition: .2s;

                &:hover {
                    font-weight: 600;
                }
            }
        }

        .menu-item{
            margin-left: 30px;
            padding-bottom: 35px;
            margin-top:40px;
            border-bottom: 3px solid transparent;
            position: relative;
            &:hover{
                text-decoration: underline;
                .menu-item__submenu{
                    display: block;
                }
            }
        }
        .menu-item__submenu{
            position: absolute;
            left: 0;
            width: 130px;
            font-size: 14px;
            display: none;
            flex-wrap: wrap;
            z-index: 1;
            top: 60px;
            margin: 0;
            a{
                font-size: 12px;
                background-color: get-color(primary);
                padding: 10px;
                display: block;
                width: 100%;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }

    .burger-icon-container {
        display: block;

        .burger-icon {
            background-color: get-color(light);

            &::before {
                background-color: get-color(light);
            }

            &::after {
                background-color: get-color(light);
            }
        }
    }
}

//menu mobile
.mobile-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    @media (min-width: 1200px) {
        display: none;
    }
    .mobile-logo {
        width: 100px;
        margin: 0;

        a{
            display: flex;
            margin: 10px 0;
        }
    }

    //controle da cor do burger menu (acesso facil)
    .burger-icon {
        background-color: get-color(primary);
        &::before {
            background-color: get-color(primary);
        }

        &::after {
            background-color: get-color(primary);
        }
    }

    .group-language {
        display: flex;
        align-items: center;
        margin-left: auto;
        gap: 8px;

        .language__list {
            display: flex;
            align-items: center;
            gap: 6px;

            a {
                &.is-active {
                    h4 {
                        font-weight: 600;
                    }
                }

                &:last-of-type {
                    h4 {
                        border-right: none;
                        margin-right: 0;
                    }
                }
            }

            h4 {
                color: get-color(light);
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0.002em;
                margin: 0;
                border-right: 1px solid get-color(light);
                padding-right: 6px;
            }
        }
    }
}

//espacamento do conteudo pra barra do topo
.main-header-clearance {
    padding-top: var(--main-header-height);
    display: none;
}

.js-open-submenu-mobile{
    display: flex;
    align-items: center;
    transition: 400ms;

    @include media(get-bp(desktop)){
        display: none;
    }
}