h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-heading;
    line-height: $line-height-heading;
    margin: $margin-heading;
}

// Paragraph styles

p {
    margin: $margin-p;

    &:last-of-type {
        margin: 0;
    }
}

// Heading sizes

h1,
.t1 {
    font-size: $font-size-h1;
}

h2,
.t2 {
    font-size: $font-size-h2;
}

h3,
.t3 {
    font-size: $font-size-h3;
}

h4,
.t4 {
    font-size: $font-size-h4;
}

h5,
.t5 {
    font-size: $font-size-h5;
}

h6,
.t6 {
    font-size: $font-size-h6;
}

// Display sizes
.d1 {
    font-size: $font-size-d1;
}
.d2 {
    font-size: $font-size-d2;
}
.d3 {
    font-size: $font-size-d3;
}

// Small sizes
small {
    display: inline-block;
    line-height: 1.4;
    opacity: 0.8;
}

small,
.s1 {
    font-size: $font-size-small;
}

.s2 {
    font-size: $font-size-smaller;
    line-height: 1.2;
}
