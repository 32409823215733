.image {
    max-width: 100%;
    position: relative;
    display: inline-block;
    line-height: 1;
}

.image.fluid {
    width: 100%;
}

.image.cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.image.block {
    display: block;
}
